import { HeaderData } from "../../../components/header/Header";
import { PayerForUser, User } from "../../../types/types";
import { useEmployerInfoQuery } from "../healthkeyAPI";
import { usePayersForUserQuery } from "./payers";
import { useListAcquiredServicePackagesQuery } from "./service-package";
import {
  useEmployerForUserQuery,
  useNotificationsQuery,
  useOnboardingTasksQuery,
  useWalletForUserQuery,
} from "./users";

export const useHeaderQuery = (
  loggedInUser: User | null,
): HeaderData & {
  isLoading: boolean;
  headerIsLoading: boolean;
  onboardingTasksIsLoading: boolean;
  payersForUserIsLoading: boolean;
} => {
  const { data: loggedInEmployer, isLoading: loggedInEmployerIsLoading } =
    useEmployerForUserQuery(loggedInUser?.id, {
      skip: !loggedInUser?.id,
    });

  const { data: onboardingTasks, isLoading: onboardingTasksIsLoading } =
    useOnboardingTasksQuery(
      { userId: loggedInUser?.id ?? "" },
      {
        skip: !loggedInUser?.id,
      },
    );

  const { data: notifications, isLoading: notificationsAreLoading } =
    useNotificationsQuery(
      { userId: loggedInUser?.id ?? "" },
      {
        skip: !loggedInUser?.id,
      },
    );

  const { data: payersForUser, isLoading: payersForUserIsLoading } =
    usePayersForUserQuery(loggedInUser?.id, {
      skip: !loggedInUser?.id,
    });

  const { data: wallet, isLoading: walletIsLoading } = useWalletForUserQuery(
    loggedInUser?.id,
    {
      skip: !loggedInUser?.id,
    },
  );

  return {
    loggedInEmployer: loggedInEmployer ?? null,
    onboardingTasks: onboardingTasks ?? [],
    payersForUser: payersForUser ?? [],
    isLoading:
      loggedInEmployerIsLoading ||
      onboardingTasksIsLoading ||
      payersForUserIsLoading ||
      walletIsLoading ||
      notificationsAreLoading,
    headerIsLoading: loggedInEmployerIsLoading || walletIsLoading,
    onboardingTasksIsLoading,
    payersForUserIsLoading,
    wallet: wallet ?? null,
    notifications: notifications ?? [],
  };
};

export const useEmployerOnboardingProgress = (
  currentEmployer: PayerForUser | null,
): {
  purchasedPackage: boolean;
  hasHealthBudgetPolicy: boolean;
  invitedUsers: number | null;
  isLoading: boolean;
} => {
  const skip = currentEmployer == null || !currentEmployer.isAdmin;

  const { data: employerInformation, isLoading: employerInformationIsLoading } =
    useEmployerInfoQuery(currentEmployer?.id, {
      skip,
      refetchOnMountOrArgChange: true,
    });

  const { data: servicePackages, isLoading: servicePackagesIsLoading } =
    useListAcquiredServicePackagesQuery(undefined as any, {
      skip,
    });

  return {
    purchasedPackage: (servicePackages && servicePackages?.length > 0) ?? false,
    hasHealthBudgetPolicy:
      (currentEmployer && currentEmployer?.payerPolicy !== null) ?? false,
    invitedUsers: employerInformation?.users.length ?? null,
    isLoading: employerInformationIsLoading || servicePackagesIsLoading,
  };
};
