import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "../store/hooks";
import { useHeaderQuery } from "../store/slices/api/aggregation-hooks";
import { User } from "../types/types";
const stripEmail = (str: string) => {
  return str.replace(
    /\/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
    "/:email",
  );
};

const stripResetToken = (str: string) => {
  return str
    .replace(
      /\/forgottenPasswordChangePassword\/\w+/,
      "/forgottenPasswordChangePassword/:resetToken",
    )
    .replace(
      /\/acceptInvite\/(\w+)\/\w+\/(\w+)/,
      "/acceptInvite/$1/:resetToken/$2",
    );
};

export const filterPrivateData = (str: string) => {
  return stripResetToken(stripEmail(str));
};

const usePageTitle = (callback: (titleText: string) => void) => {
  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      const titleText = (mutations[0].target as HTMLElement).innerText;
      if (titleText) {
        callback(titleText);
      }
    });
    observer.observe(document.querySelector("title") as any, {
      subtree: true,
      characterData: true,
      childList: true,
    });
    return () => observer.disconnect();
  }, [callback]);
};

const calculateCustomDimensions = (
  loggedInEmployer: any,
  loggedInUser: User | null,
) => {
  return [
    {
      id: 1,
      value: loggedInEmployer?.id,
    },
    {
      id: 2,
      value: loggedInUser?.id ? "true" : "false",
    },
    {
      id: 3,
      value: loggedInUser?.provider_id,
    },
    {
      id: 4,
      value: loggedInUser?.is_staff ? "true" : "false",
    },
    {
      id: 5,
      value: loggedInUser?.creating_employer ? "true" : "false",
    },
  ];
};

export const MatomoHistoryListener = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const { trackPageView, pushInstruction } = useMatomo();
  const loggedInUser = useAppSelector((state) => state.userDetails.user);
  const { loggedInEmployer, isLoading: headerIsLoading } =
    useHeaderQuery(loggedInUser);

  const [eventCache, setEventCache] = useState<any[]>([]);

  if (eventCache.length > 0 && !headerIsLoading) {
    eventCache.forEach((event) => {
      trackPageView({
        ...event,
        customDimensions: calculateCustomDimensions(
          loggedInEmployer,
          loggedInUser,
        ),
      });
    });
    setEventCache([]);
  }

  const updatedPageTitle = (titleText: string) => {
    const event = {
      documentTitle: titleText,
      href: `${process.env.REACT_APP_HOSTNAME}${filterPrivateData(location.pathname)}`,
    };

    if (!headerIsLoading || !loggedInUser?.id) {
      trackPageView({
        ...event,
        customDimensions: calculateCustomDimensions(
          loggedInEmployer,
          loggedInUser,
        ),
      });
    } else {
      setEventCache([...eventCache, event]);
    }
  };

  usePageTitle(updatedPageTitle);

  useEffect(() => {
    pushInstruction("setUserId", loggedInUser?.id);
  }, [loggedInUser?.id, pushInstruction]);

  return <>{children}</>;
};
