import { ServicePackage } from "../../../types/types";
import { healthkeyAPI } from "../healthkeyAPI";
import { cacheTime, Entity } from "./definitions";

const servicePackagesAPI = healthkeyAPI.injectEndpoints({
  endpoints: (builder) => ({
    addServiceToServicePackage: builder.mutation<
      void,
      { packageId: string; serviceId: string }
    >({
      query: ({ packageId, serviceId }) => ({
        method: "POST",
        url: `/servicePackages/${packageId}/products/${serviceId}`,
      }),
      invalidatesTags: [Entity.PackagesForAdmin, Entity.ServicePackage],
    }),
    removeServiceFromServicePackage: builder.mutation<
      void,
      { packageId: string; serviceId: string }
    >({
      query: ({ packageId, serviceId }) => ({
        method: "DELETE",
        url: `/servicePackages/${packageId}/products/${serviceId}`,
      }),
      invalidatesTags: [Entity.PackagesForAdmin, Entity.ServicePackage],
    }),
    removePayerFromServicePackage: builder.mutation<
      void,
      { packageId: string; payerId: string }
    >({
      query: ({ packageId, payerId }) => ({
        method: "DELETE",
        url: `/servicePackages/${packageId}/payers/${payerId}`,
      }),
      invalidatesTags: [Entity.PackagesForAdmin, Entity.ServicePackage],
    }),
    addServicePackageToPayer: builder.mutation<
      void,
      {
        packageId: string;
        payerId: string;
        numberOfPackagesPaidFor: number;
        startDate: string;
      }
    >({
      query: ({ packageId, payerId, numberOfPackagesPaidFor, startDate }) => ({
        method: "POST",
        body: {
          numberOfPackagesPaidFor: numberOfPackagesPaidFor,
          startDate: startDate,
        },
        url: `/servicePackages/${packageId}/payers/${payerId}`,
      }),
      invalidatesTags: [Entity.PackagesForAdmin, Entity.ServicePackage],
    }),
    servicePackageById: builder.query<ServicePackage, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.ServicePackage],
      query: (servicePackageId: string | undefined) =>
        `/servicePackages/${servicePackageId}`,
      providesTags: [Entity.ServicePackage],
    }),
    listAcquiredServicePackages: builder.query<ServicePackage[], void>({
      keepUnusedDataFor: cacheTime[Entity.ServicePackage],
      query: () => `/servicePackages/list/acquired`,
      providesTags: [Entity.ServicePackage],
    }),
    listPurchaseableServicePackages: builder.query<ServicePackage[], void>({
      keepUnusedDataFor: cacheTime[Entity.ServicePackage],
      query: () => `/servicePackages/list/purchasable`,
    }),
    uploadPackageImage: builder.mutation<
      void,
      { type: string; imageFile: File; packageId: string | undefined }
    >({
      query: ({
        type,
        imageFile,
        packageId,
      }: {
        type: string;
        imageFile: File;
        packageId: string | undefined;
      }) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", imageFile);
        return {
          method: "POST",
          url: `/servicePackages/${packageId}/uploadImage/${type}`,
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: [Entity.ServicePackage],
    }),
  }),
});

export const {
  useAddServiceToServicePackageMutation,
  useRemoveServiceFromServicePackageMutation,
  useRemovePayerFromServicePackageMutation,
  useAddServicePackageToPayerMutation,
  useServicePackageByIdQuery,
  useListAcquiredServicePackagesQuery,
  useListPurchaseableServicePackagesQuery,
  useUploadPackageImageMutation,
} = servicePackagesAPI;
