import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FeatureBundleSlugs } from "../../types/types";

type EmployerBasketState = {
  selectedPackages: string[] | null;
  selectedBundles: FeatureBundleSlugs[];
};

const initialState: EmployerBasketState = {
  selectedPackages: null,
  selectedBundles: [],
};

const employerBasketSlice = createSlice({
  name: "employerBasket",
  initialState,
  reducers: {
    setSelectedPackages(
      state: EmployerBasketState,
      action: PayloadAction<string[]>,
    ) {
      state.selectedPackages = action.payload;
    },
    setSelectedBundles(
      state: EmployerBasketState,
      action: PayloadAction<FeatureBundleSlugs[]>,
    ) {
      state.selectedBundles = action.payload;
    },
  },
});

export default employerBasketSlice;
