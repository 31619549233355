import { useMediaQuery, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import { t } from "i18next";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import authSlice from "../store/slices/auth";
import { HealthCategory, Product, User } from "../types/types";

export const handleErrorResponse = (
  err: AxiosError,
  dispatch: any,
  navigate: any,
  sessionExpiredUrl: string,
) => {
  if (
    err.response &&
    err.response.status &&
    (err.response!.status === 401 || err.response!.status === 403)
  ) {
    dispatch(authSlice.actions.logout());
    navigate(sessionExpiredUrl);
  }
};

export const buildSessionExpiredUrl = (
  response_type: string | null,
  client_id: string | null,
  redirect_uri: string | null,
  scope: string | null,
  code_challenge: string | null,
  code_challenge_method: string | null,
  nonce: string | null,
  state: string | null,
  claims: string | null,
) => {
  let path = "/login";
  if (response_type) {
    path = path + "?response_type=" + response_type + "&";
    path = path + "client_id=" + client_id + "&";
    path = path + "redirect_uri=" + redirect_uri + "&";
    path = path + "scope=" + scope + "&";
    path = path + "code_challenge=" + code_challenge + "&";
    path = path + "code_challenge_method=" + code_challenge_method + "&";
    path = path + "nonce=" + nonce + "&";
    path = path + "state=" + state + "&";
    path = path + "claims=" + claims + "&";
  } else {
    if (!toast.isActive("session-expired")) {
      toast.info(t("session-expired.toast"), {
        toastId: "session-expired",
      });
    }
  }

  return path;
};

export const buildSessionExpiredUrl2 = (
  response_type: string | null,
  client_id: string | null,
  redirect_uri: string | null,
  scope: string | null,
  code_challenge: string | null,
  code_challenge_method: string | null,
  nonce: string | null,
  state: string | null,
  claims: string | null,
) => {
  let path = "/sessionExpired";
  if (response_type) {
    path = path + "?response_type=" + response_type + "&";
    path = path + "client_id=" + client_id + "&";
    path = path + "redirect_uri=" + redirect_uri + "&";
    path = path + "scope=" + scope + "&";
    path = path + "code_challenge=" + code_challenge + "&";
    path = path + "code_challenge_method=" + code_challenge_method + "&";
    path = path + "nonce=" + nonce + "&";
    path = path + "state=" + state + "&";
    path = path + "claims=" + claims + "&";
  }
  return path;
};

export const getHealthCategoryById = (
  id: string | undefined,
  healthCategories: HealthCategory[],
): HealthCategory | undefined => {
  if (id) {
    for (let category of healthCategories) {
      if (category.id === id) {
        return category;
      }
    }
  }
  return undefined;
};

export const getHealthCategoryByHtmlId = (
  id: string | undefined,
  healthCategories: HealthCategory[],
): HealthCategory | undefined => {
  if (id) {
    for (let category of healthCategories) {
      if (category.htmlId === id) {
        return category;
      }
    }
  }
  return undefined;
};
export const getParsedDate = (date) => {
  const dateDate = new Date(date);
  return dateDate.toLocaleString("en-GB");
};

export const getParsedDateNoTime = (date) => {
  const dateDate = new Date(date);
  return dateDate.toLocaleDateString("en-GB");
};

export const isServiceInTheList = (
  serviceId: string,
  servicesList: Array<Product>,
): boolean => {
  let exists = false;
  for (let service of servicesList) {
    if (serviceId === service.id) {
      exists = true;
    }
  }
  return exists;
};

export const objToStrMap = (obj: { [x: string]: any }) => {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
};

export const getWindowLocationPathname = () => {
  return window.location.pathname;
};

export const formatNumberAsCurrency = (
  value: number,
  numberFormatOptions: Intl.NumberFormatOptions & {
    trailingZeroDisplay?: "stripIfInteger";
  } = {},
): string => {
  return (value + 0).toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    ...numberFormatOptions,
  }); // Avoid this formatting as negative zero by adding zero https://stackoverflow.com/a/77037516 -- LH, 2024-06-10
};

export const truncate = (str: string, maxLength: number = 5) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
};

export const getCurrentMoment = () => {
  return moment();
};

export const generateFileNameWithDate = (n: string): string => {
  return n + "-" + moment(new Date()).format("YYYY-MM-DD");
};

export const useDefaultHeaderHeight = (): number => {
  const theme = useTheme();
  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  return !isBiggerThanLgBreakpoint ? 72 : 80;
};

export const determineTimeUnitString = (
  number: number,
  unit: string,
): string => {
  if (number === 1) {
    return unit.substring(0, unit.length - 1);
  }
  return unit;
};

export const pathIsWebsite = (
  pathName: string | undefined,
  loggedInUser: boolean,
) => {
  return (
    typeof pathName !== "undefined" &&
    ((pathName.includes("home") && !pathName.includes("admin")) ||
      pathName.includes("help") ||
      pathName.includes("industry") ||
      pathName === "/contact" ||
      pathName === "/FAQs" ||
      pathName === "/contact-employers" ||
      pathName.includes("pricing") ||
      (pathName.includes("findCare") && !loggedInUser) ||
      pathName.includes("about") ||
      pathName === "/")
  );
};
