import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { HealthCategory, Product } from "../../types/types";

type ServicesState = {
  categories: Array<string>;

  allServices: Array<Product>;

  filteredServices: Array<Product>;

  allHealthCategories: Array<HealthCategory>;

  wishlist: Array<Product>;
};

const initialState: ServicesState = {
  categories: new Array<string>(),
  allServices: new Array<Product>(),
  filteredServices: new Array<Product>(),
  allHealthCategories: new Array<HealthCategory>(),
  wishlist: new Array<Product>(),
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setAllServices(state: ServicesState, action: PayloadAction<Product[]>) {
      state.allServices = action.payload;
      state.filteredServices = action.payload;
    },
    setCategories(state: ServicesState, action: PayloadAction<string[]>) {
      state.categories = action.payload;
    },
    setAllHealthCategories(
      state: ServicesState,
      action: PayloadAction<HealthCategory[]>,
    ) {
      state.allHealthCategories = action.payload;
    },
    setWishlist(state: ServicesState, action: PayloadAction<Product[]>) {
      state.wishlist = new Array<Product>();
      state.wishlist = action.payload;
    },
    setFilteredServices(
      state: ServicesState,
      action: PayloadAction<Product[]>,
    ) {
      state.filteredServices = new Array<Product>();
      state.filteredServices = action.payload;
    },
    clearCategories(state: ServicesState) {
      state.categories = new Array<string>();
    },
  },
});

export default servicesSlice;
