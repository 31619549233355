import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { HealthCategory, Provider, Question } from "../../types/types";

type ProvidersState = {
  providers: Array<Provider>;

  availableProviders: Array<Provider>;
  providerToAuthorise: Provider | null;
  providerAdmin: Provider | null;
  providerHealthCategories: Array<HealthCategory>;

  questions: Array<Question>;
};

const initialState: ProvidersState = {
  providers: new Array<Provider>(),
  providerToAuthorise: null,
  providerAdmin: null,
  providerHealthCategories: new Array<HealthCategory>(),
  questions: new Array<Question>(),
  availableProviders: new Array<Provider>(),
};

const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    setProviders(state: ProvidersState, action: PayloadAction<Provider[]>) {
      state.providers = [];
      state.providers = action.payload;
    },
    setAvailableProviders(
      state: ProvidersState,
      action: PayloadAction<Provider[]>,
    ) {
      state.availableProviders = [];
      state.availableProviders = action.payload;
    },
    setProviderToAuthorise(
      state: ProvidersState,
      action: PayloadAction<Provider>,
    ) {
      state.providerToAuthorise = action.payload;
    },
    setProviderAdmin(state: ProvidersState, action: PayloadAction<Provider>) {
      state.providerAdmin = action.payload;
    },
    setProviderHealthCategories(
      state: ProvidersState,
      action: PayloadAction<HealthCategory[]>,
    ) {
      state.providerHealthCategories = action.payload;
    },
    setQuestions(state: ProvidersState, action: PayloadAction<Question[]>) {
      state.questions = action.payload;
    },
  },
});

export default providersSlice;
