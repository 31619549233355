import { motion } from "framer-motion";
import React from "react";

import heartPNG from "../../../images/ContactUsHeart.svg";

export default function CircleLoader() {
  return (
    <div className="w-20 h-20 rounded-[50%] bg-blue-1 flex items-center justify-center">
      <motion.div
        className="w-6 h-6 z-50"
        variants={{
          hidden: { scale: 0, x: 10, y: 10, opacity: 0 },
          visible: {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            transition: {
              type: "tween",
              duration: 1,
            },
          },
        }}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={{
            hidden: { scale: 1 },
            visible: {
              opacity: 1,
              scale: 1.3,
              transition: {
                delay: 1.2,
                repeatType: "reverse",
                repeat: 60,
                ease: "easeIn",
                duration: 0.6,
              },
            },
          }}
          initial="hidden"
          animate="visible"
          className="w-6 h-6 z-50"
        >
          <img className="w-6 h-6x z-50" src={heartPNG} />
        </motion.div>
      </motion.div>
      <motion.span
        style={{
          display: "block",
          width: "4.6rem",
          height: "4.6rem",
          border: "0.5rem solid #E7F1FB",
          borderTop: "0.5rem solid #ffffff",
          borderRadius: "50%",
          position: "absolute",
          boxSizing: "border-box",
          backgroundColor: "#E7F1FB",
        }}
        animate={{ rotate: 360 }}
        transition={{
          repeatType: "loop",
          repeat: 15,
          ease: "linear",
          duration: 2,
        }}
      />
    </div>
  );
}
