import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addToSearchHistory,
  deleteSearchHistory,
  getSearchHistory,
} from "../../services/search-service";

type SearchState = {
  history: { query: string; date: string }[];
};

const initialState: SearchState = {
  history: getSearchHistory(),
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    addToSearchHistory(
      state: SearchState,
      action: PayloadAction<{ query: string }>,
    ) {
      state.history = addToSearchHistory(action.payload.query);
    },
    deleteSearchHistory(state: SearchState) {
      state.history = [];
      deleteSearchHistory();
    },
  },
});

export default searchSlice;
