import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Wallet } from "../../types/types";

type TransactionsState = {
  wallet: Wallet | null;
  totalSpending: number;
};

const initialState: TransactionsState = {
  wallet: null,
  totalSpending: 0,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setWallet(state: TransactionsState, action: PayloadAction<Wallet>) {
      state.wallet = action.payload;
    },
  },
});

export default transactionsSlice;
