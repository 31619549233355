import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OnboardingRemindersState = {
  onboardingReminderSeen: boolean;
};

const initialState: OnboardingRemindersState = {
  onboardingReminderSeen: false,
};

const onboardingRemindersSlice = createSlice({
  name: "onboarding-reminders",
  initialState,
  reducers: {
    setOnboardingReminderSeen(
      state: OnboardingRemindersState,
      action: PayloadAction<boolean>,
    ) {
      state.onboardingReminderSeen = action.payload;
    },
  },
});

export default onboardingRemindersSlice;
