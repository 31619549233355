import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "../../types/types";

type AuthState = {
  token: string | null;
  refreshToken: string | null;
  account: User | null;
  redirectUrl: string | null;
};

const initialState: AuthState = {
  token: null,
  refreshToken: null,
  account: null,
  redirectUrl: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(
      state: AuthState,
      action: PayloadAction<{ token: string; refreshToken: string }>,
    ) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    setAccount(state: AuthState, action: PayloadAction<User>) {
      state.account = action.payload;
    },
    logout(state: AuthState) {
      state = initialState;
    },
    setRedirectUrl(state: AuthState, action: PayloadAction<string | null>) {
      state.redirectUrl = action.payload;
    },
    refreshAuthToken(
      state: AuthState,
      action: PayloadAction<{ access?: string }>,
    ) {
      state.token = action.payload.access ? action.payload.access : null;
      state.refreshToken = null;
    },
  },
});

export default authSlice;
