import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/react";
import moment from "moment";

import {
  AnalyticsEvent,
  ContactUs,
  CreateServicePackagePaymentResponse,
  EmployerInformation,
  EmployerInsights,
  EmployerMonthlyBudgetInformation,
  EmployerPayment,
  FeatureBundle,
  FinishPurchase,
  HealthCategory,
  Payer,
  PurchaseTransaction,
  RespondToUserJoiningEmployer,
  SalarySacrificeReportParam,
  StackOneAccount,
  StackOneConnectSession,
  StackOneCreateConnectSession,
  StackOneImportPreview,
  TestingUserId,
  TopUp,
  UpdateProvidersForEmployer,
  UserForSalarySacrificeReport,
  UserSubscription,
} from "../../types/types";
import { cacheTime, Entity } from "./api/definitions";
import authSlice from "./auth";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_SERVER_API_URL}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const formDataEndpoints = ["uploadEmployerImage", "uploadPackageImage"];
    // We do not set the content type for multipart form data as fetch will do this with the boundary -- LH, 2024-04-04
    if (!formDataEndpoints.includes(endpoint)) {
      headers.set("Content-Type", "application/json");
    }
    const token = (getState() as any).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  responseHandler: async (response) => {
    switch (response.headers.get("content-type")) {
      case "application/json":
        return response.json();
      default:
        return response.text();
    }
  },
});

const baseQueryPython = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as any).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  responseHandler: async (response) => {
    switch (response.headers.get("content-type")) {
      case "application/json":
        return response.json();
      default:
        return response.text();
    }
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log("sending refresh token");
    // send refresh token to get new access token
    const refreshResult = await baseQueryPython(
      {
        url: "/token/refresh/",
        body: { refresh: (api.getState() as any).auth.refreshToken },
        method: "POST",
      },
      api,
      extraOptions,
    );
    if (refreshResult.data) {
      // store the new token
      api.dispatch(authSlice.actions.refreshAuthToken(refreshResult.data));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authSlice.actions.logout());
    }
  }

  if (result?.error?.status === 503) {
    Sentry.captureMessage(
      "Request Responded 503. This could be caused by NGINX rate limiting",
    );
  }

  return result;
};

export const healthkeyAPI = createApi({
  reducerPath: "healthkeyAPI",
  tagTypes: [
    Entity.Employer,
    Entity.EmployersForProvider,
    Entity.EmployerForUser,
    Entity.ProviderToEdit,
    Entity.Provider,
    Entity.Wallet,
    Entity.Purchase,
    Entity.EmployerInfo,
    Entity.EmployerInsights,
    Entity.EmployerBudgetInfo,
    Entity.HealthCategory,
    Entity.Service,
    Entity.ServicePackage,
    Entity.Wishlist,
    Entity.BudgetHistory,
    Entity.HealthCategoryPreference,
    Entity.HealthAreaPreference,
    Entity.StackOneConnectedAccount,
    Entity.Content,
    Entity.PayersForAdmin,
    Entity.ServicesForAdmin,
    Entity.ContentForAdmin,
    Entity.AdminsForProvider,
    Entity.OnboardingTask,
    Entity.PayersForUser,
    Entity.AdminsForPayer,
    Entity.UserSubscriptions,
    Entity.Notification,
    Entity.ServiceOutput,
    Entity.RecommendationsForUser,
    Entity.HealthCategoryForAdmin,
    Entity.ProviderReviewRequest,
    Entity.ProviderSpotlight,
    Entity.PackagesForAdmin,
    Entity.HealthAreaProductOrdering,
    Entity.SearchMeta,
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    healthAreas: builder.query<HealthCategory[], void>({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: () => `/healthAreas`,
    }),
    healthAreasChildren: builder.query<Map<string, HealthCategory[]>, void>({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: () => `/healthAreas/children`,
    }),
    healthAreaChildren: builder.query<HealthCategory[], string>({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: (healthAreaId: string) => `/healthAreas/children/${healthAreaId}`,
    }),
    subscriptions: builder.query<
      UserSubscription[],
      { userId?: string; activeOnly: boolean }
    >({
      keepUnusedDataFor: cacheTime[Entity.UserSubscriptions],
      query: ({ userId, activeOnly }) => ({
        method: "GET",
        url: `/users/${userId}/subscriptions?activeOnly=${activeOnly}`,
      }),
      providesTags: [Entity.UserSubscriptions],
    }),
    currentEmployer: builder.query<Payer, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.Employer],
      query: (employerId: string) => ({
        url: `/payers/${employerId}/full`,
      }),
      providesTags: [Entity.Employer],
    }),
    partialEmployer: builder.query<Payer, string | undefined>({
      query: (employerId: string) => ({
        url: `/payers/${employerId}/partial`,
      }),
    }),
    employerInfo: builder.query<EmployerInformation, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmployerInfo],
      query: (employerId: string) => ({
        url: `/payers/${employerId}/employerInfo`,
      }),
      providesTags: [Entity.EmployerInfo],
    }),
    employerInsights: builder.query<EmployerInsights, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmployerInsights],
      query: (employerId: string) => ({
        url: `/payers/${employerId}/insights`,
      }),
      providesTags: [Entity.EmployerInsights],
    }),
    employerBudgetInfo: builder.query<
      Record<string, EmployerMonthlyBudgetInformation>,
      string | undefined
    >({
      keepUnusedDataFor: cacheTime[Entity.EmployerBudgetInfo],
      query: (employerId: string) => ({
        url: `/payers/${employerId}/budgetInformation`,
      }),
    }),
    usersForSalarySacrificeReport: builder.query<
      UserForSalarySacrificeReport[],
      SalarySacrificeReportParam
    >({
      keepUnusedDataFor: 0,
      query: (param: SalarySacrificeReportParam) => ({
        url: `/payers/${param.employerId}/salarySacrificeReport${param.timeframe === null ? "" : "/" + param.timeframe}`,
      }),
    }),
    stackOneConnectedAccount: builder.query<StackOneAccount, void>({
      keepUnusedDataFor: cacheTime[Entity.StackOneConnectedAccount],
      query: () => ({
        url: `/stackone/connectedAccount`,
      }),
      providesTags: [Entity.StackOneConnectedAccount],
    }),
    stackOneImportPreview: builder.query<StackOneImportPreview, void>({
      keepUnusedDataFor: cacheTime[Entity.StackOneImportPreview],
      query: () => ({
        url: `/stackone/importPreview`,
      }),
    }),

    listPurchasableBundles: builder.query<FeatureBundle[], void>({
      keepUnusedDataFor: cacheTime[Entity.FeatureBundle],
      query: () => `/featureBundles/list/purchasable`,
    }),

    updateProvidersForEmployer: builder.mutation<
      void,
      UpdateProvidersForEmployer
    >({
      query: (updateProviders: UpdateProvidersForEmployer) => ({
        method: "PATCH",
        body: { selectedProvidersIds: updateProviders.selectedProvidersIds },
        url: `/payers/${updateProviders.employerId}`,
      }),
      invalidatesTags: [Entity.Provider],
    }),

    acceptTermsAndConditions: builder.mutation<Payer, string>({
      query: (employerId: string) => ({
        method: "PATCH",
        url: `/payers/${employerId}/acceptTAndC`,
      }),
      invalidatesTags: [Entity.Employer, Entity.OnboardingTask],
    }),
    createEmployer: builder.mutation<
      Payer,
      Pick<Payer, "name" | "parentPayerId">
    >({
      query: (employer) => ({
        method: "POST",
        url: `/payers?type=employer`,
        body: employer,
      }),
      invalidatesTags: [
        Entity.PayersForUser,
        Entity.EmployerForUser,
        Entity.Employer,
        Entity.OnboardingTask,
        Entity.ServicePackage,
      ],
    }),
    deleteEmployer: builder.mutation<void, string>({
      query: (employerId) => ({
        method: "DELETE",
        url: `/payers/${employerId}`,
      }),
      invalidatesTags: [Entity.PayersForAdmin],
    }),
    finishPurchase: builder.mutation<void, FinishPurchase>({
      query: (finishPurchase: FinishPurchase) => ({
        method: "PATCH",
        body: {
          useSalarySacrifice: finishPurchase.salarySacrifice ? true : false,
        },
        url: `/transactions/${finishPurchase.transactionId}/${finishPurchase.action}`,
      }),
      invalidatesTags: [
        Entity.Wallet,
        Entity.Purchase,
        Entity.BudgetHistory,
        Entity.UserSubscriptions,
      ],
    }),
    startPurchase: builder.mutation<void, PurchaseTransaction>({
      query: (startPurchase) => ({
        method: "POST",
        body: startPurchase,
        url: `/transactions`,
      }),
      invalidatesTags: [Entity.Purchase],
    }),
    finishTopUpPayment: builder.mutation<void, string>({
      query: (paymentIntent: string) => ({
        method: "PATCH",
        url: `/payments/finaliseTopUpPaymentIntent/${paymentIntent}`,
      }),
      invalidatesTags: [Entity.Wallet, Entity.BudgetHistory],
    }),
    respondToUserJoiningEmployer: builder.mutation<
      EmployerInformation,
      RespondToUserJoiningEmployer
    >({
      query: (respond: RespondToUserJoiningEmployer) => ({
        method: "PATCH",
        body: {
          approve: respond.approve,
        },
        url: `/payers/${respond.employerId}/users/${respond.userId}`,
      }),
      invalidatesTags: [Entity.EmployerInfo],
    }),
    contactUs: builder.mutation<void, ContactUs>({
      query: (contactUs: ContactUs) => ({
        method: "POST",
        body: contactUs,
        url: `/admin/contactUs`,
      }),
      invalidatesTags: [Entity.Wallet, Entity.Purchase, Entity.BudgetHistory],
    }),
    uploadEmployerImage: builder.mutation<
      void,
      { type: string; imageFile: File; payerId: string | undefined }
    >({
      query: ({
        type,
        imageFile,
        payerId,
      }: {
        type: string;
        imageFile: File;
        payerId: string | undefined;
      }) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", imageFile);
        return {
          method: "POST",
          url: `/payers/${payerId}/uploadImage/${type}`,
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: [Entity.EmployerForUser, Entity.Employer],
    }),
    topupUser: builder.mutation<void, TopUp>({
      invalidatesTags: [Entity.EmployerInfo],
      query: (topup) => ({
        method: "POST",
        url: `/transactions/topUp`,
        body: topup,
      }),
    }),
    createStackOneConnectSession: builder.mutation<
      StackOneConnectSession,
      StackOneCreateConnectSession
    >({
      query: (createConnectSession: StackOneCreateConnectSession) => ({
        method: "POST",
        url: "/stackone/connectSession",
        body: createConnectSession,
      }),
    }),
    activateStackOneConnection: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/stackone/activateConnection",
      }),
      invalidatesTags: [Entity.Employer],
    }),
    deactivateStackOneConnection: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/stackone/deactivateConnection",
      }),
      invalidatesTags: [Entity.Employer, Entity.StackOneConnectedAccount],
    }),
    syncStackOne: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/stackone/sync",
      }),
      invalidatesTags: [Entity.EmployerInfo, Entity.OnboardingTask],
    }),
    createEmployerPaymentIntent: builder.mutation<
      CreateServicePackagePaymentResponse,
      EmployerPayment
    >({
      query: (createEmployerPlanPaymentIntent: EmployerPayment) => ({
        method: "POST",
        url: `/payments/createEmployerPlanPaymentIntent`,
        body: createEmployerPlanPaymentIntent,
      }),
    }),
    finalizeServicePackagePaymentIntent: builder.mutation<void, string>({
      query: (paymentIntent: string) => ({
        method: "PATCH",
        url: `/payments/finaliseEmployerPlanPaymentIntent/${paymentIntent}`,
      }),
      invalidatesTags: [
        Entity.PayersForUser,
        Entity.Employer,
        Entity.ServicePackage,
        Entity.OnboardingTask,
      ],
    }),
    createAnalyticsEvent: builder.mutation<void, AnalyticsEvent>({
      query: (event: AnalyticsEvent) => ({
        method: "POST",
        url: `/analytics`,
        body: { ...event, date: moment(event.date).format("yyyy-MM-DD HH:mm") },
      }),
    }),
    resetTestData: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/testing/resetTestData",
      }),
    }),
    getUserIdForTesting: builder.query<TestingUserId, string>({
      query: (email) => ({
        url: `/testing/getUserId/${email}`,
      }),
    }),
  }),
});

export const {
  useHealthAreasQuery,
  useHealthAreasChildrenQuery,
  useHealthAreaChildrenQuery,
  useEmployerInfoQuery,
  useEmployerInsightsQuery,
  useAcceptTermsAndConditionsMutation,
  useCreateEmployerMutation,
  useCurrentEmployerQuery,
  useUpdateProvidersForEmployerMutation,
  useFinishPurchaseMutation,
  useStartPurchaseMutation,
  useFinishTopUpPaymentMutation,
  usePartialEmployerQuery,
  useRespondToUserJoiningEmployerMutation,
  useEmployerBudgetInfoQuery,
  useUsersForSalarySacrificeReportQuery,
  useStackOneConnectedAccountQuery,
  useStackOneImportPreviewQuery,
  useListPurchasableBundlesQuery,
  useGetUserIdForTestingQuery,
  useUploadEmployerImageMutation,
  useContactUsMutation,
  useTopupUserMutation,
  useCreateStackOneConnectSessionMutation,
  useActivateStackOneConnectionMutation,
  useDeactivateStackOneConnectionMutation,
  useSyncStackOneMutation,
  useCreateEmployerPaymentIntentMutation,
  useFinalizeServicePackagePaymentIntentMutation,
  useCreateAnalyticsEventMutation,
  useDeleteEmployerMutation,
  useResetTestDataMutation,
  useSubscriptionsQuery,
} = healthkeyAPI;
