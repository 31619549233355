import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserRegistered } from "../../types/types";

type UsersState = {
  usersRegistered: UserRegistered[] | null;
};

const initialState: UsersState = {
  usersRegistered: new Array<UserRegistered>(),
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersInformation(
      state: UsersState,
      action: PayloadAction<UserRegistered[]>,
    ) {
      state.usersRegistered = action.payload;
    },
  },
});

export default usersSlice;
